<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
            :label="$t('customer.search.label')"
            :placeholder="$t('customer.search.placeholder')"
            v-model="search.search"
            solo
            clearable
            hide-details
            @click:clear="searchClear"
            @keypress.enter="searchItem"
        ></v-text-field>
      </v-col>
      <v-col
          class="d-flex ml-md-n5"
          cols="12"
          sm="6"
          md="4"
          lg="4"
      >
        <v-select
            v-model="search.search_select"
            :items="search_from"
            label="Search from"
            :append-icon="'mdi-chevron-down'"
            solo
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <v-btn
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="primary"
            medium
            class="ml-1"
            @click="searchItem"
        >
          <v-icon size="32">mdi-magnify</v-icon>
        </v-btn>
      </v-col>
      <v-col
          class="d-flex justify-end"
          sm="12"
          md="5"
          lg="5"
      >
        <v-btn
            :to="{name: 'customers.create'}"
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="green"
            dark
            medium
            class="ml-auto"
            v-if="mxware.can('customer-create')"
        >
          <v-icon size="32">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div :class="{'mmobile': isMobile === true}" :style="`overflow: auto; position: relative; display: inline-block; width: 100%; max-height: ${height}px;`">
      <table class="mx-table-full"  :id="table_id">
        <thead class="">
        <tr class="red">
          <th style="min-width: 40px; max-width: 40px;">ID</th>
          <th style="min-width: 120px; max-width: 120px;">{{ $t('customer.company_name') }}</th>
          <th style="min-width: 100px; max-width: 100px;">{{ $t('customer.gender.official.title') }}</th>
          <th style="min-width: 160px; max-width: 160px;">{{ $t('customer.first_name') }}</th>
          <th style="min-width: 160px; max-width: 160px;">{{ $t('customer.last_name') }}</th>
          <th style="min-width: 240px;">{{ $t('customer.email') }}</th>
          <th>{{ $t('customer.is_deleted') }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="xm-table-ld-cursor--default">
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="{'active': item.id === item_id}"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.company_name }}</td>
          <td>{{ $t(`customer.gender.official.${item.gender}`) }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ $t(`bool.${ item.is_deleted === 0 ? 'no': 'yes' }`) }}</td>
          <td>
            <v-btn v-if="mxware.can('customer-update')" text elevation="0" small :to="{name: 'customers.edit', params: {id: item.id}}">
              <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <v-pagination
        v-if="total_pages > 1"
        v-model="page"
        class="my-4"
        :total-visible="7"
        :length="total_pages"
    ></v-pagination>
  </div>

</template>

<script>

import moment from 'moment'

export default {
  props: {
    table_id: {
      type: String,
      default: 'ts'
    }
  },
  data() {
    return {
      page: 1,
      meta: 20,
      total_pages: 0,
      isMobile: true,
      height: 0,
      items: [],
      item_id: 1,

      search: {
        search: null,
        search_select: 'last_name'
      },
      search_from: [
        {
          text: this.$t('customer.search.select.first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('customer.search.select.last_name'),
          value: 'last_name'
        },
        {
          text: this.$t('customer.search.select.email'),
          value: 'email'
        },
        // {
        //   text: this.$t('customer.search.select.identification'),
        //   value: 'identification'
        // },
      ],
    }
  },
  watch: {
    page() {
      if (this.search.search === null) {
        this.getItems()
      } else {
        this.searchItem()
      }
    },
    'search.search'() {
      if (this.search.search === '') {
        this.search.search = null
        this.getItems()
      }
    }
  },
  created() {
    setTimeout(this.tableHeight, 300)
    this.getItems()
  },
  mounted() {
    this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById(this.table_id).getBoundingClientRect().top + 100)
    },
    getId(id) {
      this.item_id = id
      this.$emit('getId', id)
    },
    tableCreated() {
      this.$emit('tableCreated', true)
    },
    searchItem() {
      if (this.search.search !== null) {
        this.$store.dispatch('customerSearch', this.search).then(() => {
          this.total_pages = 1
          this.items = this.$store.getters.customers.data
        })
      }
    },
    getItems() {
      this.$store.dispatch('customers', this.page).then(() => {
        this.generateItems()
      })
    },
    generateItems() {
      this.items = this.$store.getters.customers.data
      this.meta = this.$store.getters.customers.meta
      this.page = this.$store.getters.customers.meta.current_page

      this.total_pages = Math.ceil(this.$store.getters.customers.meta.total / 1000)
      this.tableCreated()
    },
    searchClear () {
      this.getItems()
    },
    getBirthday(date,format='YYYY-MM-DD') {
      return moment(date, format).format('DD.MM.YYYY')
    }
  }
}
</script>
